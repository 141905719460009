import React, { useState, useEffect, memo } from 'react';

import './App.css';
import Startup from './pages/Startup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import Notification from './components/Notification';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectDomain, selectNotification } from './store/selectors';
import { clearNotification } from './store/actions';
import { withRouter } from 'react-router-dom';
import Consent from './pages/Consent';
import ConsentSettings from './pages/ConsentSettings';
import { getCookie } from './utils/tools';

const App = (props) => {
    const { notification ={}, dispatch, location = {} } = props;
    const { state = {} } = location;
    const [loader, setLoader] = useState(true);
    const cookieConsent = getCookie('consent-settings');
    
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm') || theme.breakpoints.up('xs'));

    function isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    const rotateDeviceContainer = document.querySelector('#rotateDevice');
    if (!isMobile() && rotateDeviceContainer) {
        console.log('rotateDevice removed');
        rotateDeviceContainer.remove();
    }
/*
    useEffect(() => {
        setLoader(false);
        //setTimeout(() => setLoader(false), 3000);
    }, []);

    if (loader) {
        return <Startup {...props} />
    }
	*/
    
    if(!cookieConsent) {
        return state && state.consentSettings ? <ConsentSettings {...props} /> : <Consent {...props} />
    }

var bodyClass = location.pathname.replaceAll('/','');
// console.log('body class is'+bodyClass+'_body');
//className+= ' '+bodyClass+'_body';
var bClass = "main-container"+' '+bodyClass+'_body';

document.body.setAttribute("class", bodyClass+'body');
    return (
        <div className={bClass}>
            {isDesktop ? <DesktopView {...props} /> : <MobileView {...props} />}
            <Notification {...notification} handleClose={() => dispatch(clearNotification())} />
        </div>
    );
}



const mapStateToProps = createStructuredSelector({
    domain: selectDomain(),
    notification: selectNotification(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}


const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withRouter,
    withConnect,
    memo
)(App);

