/**
 *
 * Schema
 *
 */

import {lazy} from 'react';
import React, {Component} from 'react'

import Bondrock from './images/categories/bondrock-mv-rockwool-komposition_comp.jpg';
import CoverrockX2 from './images/categories/coverrock-x-2-rockwool-platte_comp.jpg';
import FillrockKD from './images/categories/fillrock-kd-plus_comp.jpg';
import FillrockRG from './images/categories/fillrock-rg-plus_comp.jpg';
import Fixrock from './images/categories/fixrock-rockwool-komposition_comp.jpg';
import FloorrockTherm from './images/categories/floorrock-therm-rockwool-komposition_comp.jpg'
import FloorrockAcousticCP5 from './images/categories/floorrock-acoustic-cp5-rockwool-komposition_comp.jpg'
import Formrock from './images/categories/formrock-rockwool-komposition_comp.jpg';
import Hardrock from './images/categories/hardrock-038-rockwool-paket-platte-recyclable_comp.jpg';
import Klemmrock from './images/categories/klemmrock-rockwool-komposition_comp.jpg';
import Masterrock from './images/categories/masterrock-035-rockwool-platte_comp.jpg';
import PlanarockTop from './images/categories/planarock-top-rockwool-komposition_comp.jpg';
import TegarockL from './images/categories/tegarock-l-rockwool-komposition_comp.jpg';

export const routes = [
    {
        id: 1,
        name: 'home',
        path: '/',
        exact: true,
        component: lazy(() => import('./pages/Home')),
        data: {
            title: 'Home'
        }
    },
    {
        id: 2,
        name: 'screen1',
        path: '/screen1',
        exact: true,
        component: lazy(() => import('./pages/Overlay')),
        data: {
            title: 'Screen1'
        }
    },
    {
        id: 3,
        name: 'screen2',
        path: '/screen2',
        exact: true,
        component: lazy(() => import('./pages/Overlay')),
        data: {
            title: 'Screen2'
        }
    },
    {
        id: 4,
        name: 'screen3',
        path: '/screen3',
        exact: true,
        component: lazy(() => import('./pages/Overlay')),
        data: {
            title: 'Screen3'
        }
    },
    {
        id: 5,
        name: 'nutzungsbedingungen',
        path: '/nutzungsbedingungen',
        exact: true,
        component: lazy(() => import('./pages/Nutzungsbedingungen')),
        data: {
            title: 'Nutzungsbedingungen'
        }
    },
    {
        id: 6,
        name: 'impressum',
        path: '/impressum',
        exact: true,
        component: lazy(() => import('./pages/Impressum')),
        data: {
            title: 'Impressum'
        }
    },
    {
        id: 7,
        name: 'datenschutz',
        path: '/datenschutz',
        exact: true,
        component: lazy(() => import('./pages/Datenschutz')),
        data: {
            title: 'Datenschutz'
        }
    },
    {
        id: 8,
        name: 'calculator',
        path: '/calculator',
        exact: true,
        component: lazy(() => import('./pages/Calculator')),
        data: {
            title: 'Calculator'
        }
    },
    {
        id: 9,
        name: 'manual',
        path: '/manual',
        exact: true,
        component: lazy(() => import('./pages/Manual')),
        data: {
            title: 'Anleitung'
        }
    },
    {
        id: 10,
        name: 'funding',
        path: '/funding',
        exact: true,
        component: lazy(() => import('./pages/Funding')),
        data: {
            title: 'Fordermittel'
        }
    },
    {
        id: 11,
        name: 'product',
        path: '/product',
        exact: true,
        component: lazy(() => import('./pages/Product')),
        data: {
            title: 'Produktinfo'
        }
    },
    {
        id: 12,
        name: 'report',
        path: '/report',
        component: lazy(() => import('./pages/Report')),
        data: {
            title: 'Ergebnisse als PDF'
        }
    },
    {
        id: 13,
        name: 'acknowledgement',
        path: '/acknowledgement',
        component: lazy(() => import('./pages/Acknowledgement')),
        data: {
            title: 'Acknowledgement'
        }
    },
    {
        id: 14,
        name: 'practice',
        path: '/practice',
        exact: true,
        component: lazy(() => import('./pages/Practice')),
        data: {
            title: 'Profi'
        }
    },
    {
        id: 15,
        name: 'downloadPDF',
        path: '/downloadpdf',
        exact: true,
        component: lazy(() => import('./pages/DownloadPDF')),
        data: {
            title: 'Bericht herunterladen'
        }
    }
];

export const ReportPageRoutes = [
    {
        id: 1,
        name: 'downloadpdf',
        path: '/report/downloadpdf',
        component: lazy(() => import('./pages/DownloadPDF')),
        data: {
            title: 'Bericht herunterladen'
        }
    },
    {
        id: 2,
        name: 'userDetails',
        path: '/report/userDetails',
        component: lazy(() => import('./pages/UserDetails')),
        data: {
            title: 'User Details'
        }
    },
    {
        id: 3,
        name: 'createPractice',
        path: '/report/createPractice',
        component: lazy(() => import('./pages/CreatePractice')),
        data: {
            title: 'Create Practice'
        }
    }
];

export const ReportPageTabs = [
    {
        id: 1,
        name: 'downloadpdf',
        label: 'Bericht herunterladen',
        viewTab: true,
        path: '/report/downloadpdf'
    },
    {
        id: 2,
        name: 'shipping',
        label: 'Profi: Bericht für Kunden',
        viewTab: true,
        path: '/report/createPractice'
    }
];

export const ProductCategories = [
    {
        id: 1,
        fullName: 'Dachdämmplatte Bondrock® MV',
        name: 'Bondrock',
        descriptions: [{
            description: 'Druckbelastbare Steinwolle-Dachdämmplatte mit oberseitiger Mineralvlieskaschierung (MV) für die direkte Heiß- und Kaltverklebung der Dachabdichtung.',
            regex: /^.*$/
        }],
        imageUrl: Bondrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>druckbelastbar</li>
                <li>diffusionsoffen</li>
                <li>heißbitumenverträglich</li>
                <li>chemisch neutral</li>
                <li>dimensionsstabil unter Temperaturänderung</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/bondrock-mv/"
    },
    /* old / removed products
     {
        id: 2,
        fullName: '', // don't forget to set if this product should be added
        name: 'Cliprock',
        description: 'Cliprock desc..',
        imageUrl: Cliprock
    },
    {
        id: 3,
        fullName: '', // don't forget to set if this product should be added
        name: 'Coverrock',
        description: 'Coverrock desc..',
        imageUrl: Coverrock
    }, */
    {
        id: 4,
        fullName: 'Putzträgerplatte Coverrock® X-2',
        name: 'Coverrock X-2',
        descriptions: [{
            description: 'Nichtbrennbare Putzträgerplatte aus Steinwolle in der Wärmeleitfähigkeit 035 als Kernstück für mineralische Wärmedämm-Verbundsysteme. Die Beschichtung auf den Plattenoberflächen ermöglicht die maschinelle Verarbeitung des Klebemörtels auf dem Untergrund sowie den Putzauftrag ohne Pressspachtelung.',
            regex: /^.*$/
        }],
        imageUrl: CoverrockX2,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude - werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000°C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>schallabsorbierend</li>
                <li>wasserabweisend</li>
                <li>diffusionsoffen</li>
                <li>beidseitige Haftbrückenbeschichtung</li>
                <li>schnell und einfach zu verarbeiten</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/coverrock-x-2/"
    },
    {
        id: 5,
        fullName: 'Einblasdämmung Fillrock® KD Plus',
        name: 'Fillrock KD',
        descriptions: [{
            description: 'Flockenförmiges Granulat aus Steinwolle, das mittels einer speziellen Maschinentechnik in die Hohlschicht eines zweischaligen Mauerwerks eingeblasen wird.',
            regex: /^.*$/
        }],
        imageUrl: FillrockKD,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – An der Verwendungsstelle hergestellte Wärmedämmung aus Mineralwolle (MW) gem. DIN EN 14064-1, ETA-18/1023 und EAD 040729-00-1201</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>wärmedämmend</li>
                <li>schalldämmend</li>
                <li>wasserabweisend</li>
                <li>diffusionsoffen</li>
                <li>schnell und einfach zu verarbeiten</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/fillrock-kd-plus/"
    },
    {
        id: 6,
        fullName: 'Einblasdämmung Fillrock® RG Plus',
        name: 'Fillrock RG',
        descriptions: [{
            description: 'Flockenförmiges Granulat aus Steinwolle, das mittels einer speziellen Maschinentechnik in Hohlräume, wie z. B. Holzständerwände oder Dächer, eingeblasen oder auf waagerechte oder geneigte Flächen aufgeblasen wird.',
            regex: /^.*$/
        }],
        imageUrl: FillrockRG,
        fullDescription: <ul>
            <li>Wärmedämmstoff für Gebäude – An der Verwendungsstelle hergestellte Wärmedämmung aus Mineralwolle (MW) gem. DIN EN 14064-1, ETA-18/1023 und EAD 040729-00-1201</li>
            <li>WLS 035</li>
            <li>nichtbrennbar</li>
            <li>Schmelzpunkt &gt; 1000 °C</li>
            <li>nicht glimmend</li>
            <li>wärme- und schalldämmend</li>
            <li>schallabsorbierend</li>
            <li>wasserabweisend</li>
            <li>diffusionsoffen</li>
            <li>KEYMARK Güteüberwachung</li>
        </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/fillrock-rg-plus/"
    },
    {
        id: 7,
        fullName: 'Fassadendämmplatte Fixrock® 033',
        name: 'Fixrock',
        descriptions: [{
            description: 'Nichtbrennbare, formstabile Steinwolle-Dämmplatte in der Wärmeleitfähigkeit 033. Geeignet für die Dämmung von vorgehängten hinterlüfteten Fassaden.',
            regex: /^.*$/
        }],
        imageUrl: Fixrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>wasserabweisend</li>
                <li>diffusionsoffen</li>
                <li>schnell und einfach zu verlegen</li>
                <li>recycelbar</li>
                <li>Fixrock 033, Dicke 100 bis 200 mm: Befestigung mit nur 1 Dübel pro Platte möglich</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/fixrock-033/"
    },
    {
        id: 8,
        fullName: 'Steinwolle-Dämmplatte Formrock® 035',
        name: 'Formrock',
        descriptions: [{
            description: 'Auf Format geschnittene Steinwolle-Dämmplatte, die ohne Zuschneiden genau zwischen die Latten/Profile der Unterkonstruktion passt.',
            regex: /^.*$/
        }],
        imageUrl: Formrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000°C</li>
                <li>nicht glimmend</li>
                <li>hoch wärmedämmend</li>
                <li>ohne Zuschneiden einzubauen</li>
                <li>die Plattenbreite entspricht dem lichten Abstand der Latten</li>
                <li>hervorragende Klemmwirkung</li>
                <li>Format: 1000 x 450 mm</li>
                <li>recyclebar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/formrock-035/"
    },
    {
        id: 9,
        fullName: 'Dachdämmplatte Hardrock® 038',
        name: 'Hardrock',
        descriptions: [{
            description: 'Druckbelastbare, hoch wärmedämmende Steinwolle-Dachdämmplatte mit integrierter Zweischichtcharakteristik in der Wärmeleitfähigkeit 038. Aufgrund ihrer besonders hoch verdichteten, lastverteilenden Oberlage bietet sie eine verbesserte Widerstandsfähigkeit gegen mechanische Beanspruchungen.',
            regex: /^.*$/
        }],
        imageUrl: Hardrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>druckbelastbar</li>
                <li>diffusionsoffen</li>
                <li>chemisch neutral</li>
                <li>dimensionsstabil unter Temperaturänderung</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/hardrock-038/"
    },
    {
        id: 10,
        fullName: 'Dämmfilz Klemmrock® 035',
        name: 'Klemmrock',
        descriptions:
            [{
                description: 'Hochkomprimierter Steinwolle-Dämmfilz in Rollenform für die auf Maß geschnittene Dämmung zwischen den Sparren.',
                regex: /^Schrägdach.*$/
            }, {
                description: 'Hochkomprimierter Steinwolle-Dämmfilz für die nachträgliche Wärmedämmung der Geschossdecken in der Wärmeleitfähigkeit 035.',
                regex: /^.*$/
            }],
        imageUrl: Klemmrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>hoch komprimiert</li>
                <li>diffusionsoffen</li>
                <li>rationell zu lagern und zu transportieren</li>
                <li>schnell und einfach zu verlegen</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/klemmrock/"
    },
    {
        id: 11,
        fullName: 'Dachdämmplatte Masterrock® 035',
        name: 'Masterrock',
        descriptions: [{
            description: 'Hochverdichtete Steinwolle-Dämmplatte mit extra harter Oberlage für die Aufsparrendämmung in der Wärmeleitfähigkeit 035.',
            regex: /^.*$/
        }],
        imageUrl: Masterrock,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000°C</li>
                <li>in hohem Maße wärmedämmend</li>
                <li>schalldämmend</li>
                <li>wasserabweisend</li>
                <li>diffusionsoffen</li>
                <li>schnell und einfach zu verlegen</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/masterrock-035/"
    },
    /* old / removed products
     {
        id: 12,
        fullName: '', // don't forget to set if this product should be added
        name: 'Megarock',
        description: 'Megarock desc..',
        imageUrl: Megarock
    }, */
    {
        id: 13,
        fullName: 'Kellerdeckenplatte Planarock® Top',
        name: 'Planarock Top',
        descriptions: [{
            description: 'Steinwolle-Dämmplatte mit einseitiger, heller Glasvlieskaschierung für die unterseitige Dämmung von Kellerdecken ohne optische Anforderungen. Kein Farbauftrag möglich',
            regex: /^.*$/
        }],
        imageUrl: PlanarockTop,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000 °C</li>
                <li>nicht glimmend</li>
                <li>einseitige, helle Glasvlieskaschierung</li>
                <li>Oberfläche mit erhöhter Druckfestigkeit</li>
                <li>hoch wärme- und schalldämmend</li>
                <li>wasserabweisend</li>
                <li>diffusionsoffen</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/planarock-top"
    },
    /* old / removed products
    {
        id: 14,
        fullName: '', // don't forget to set if this product should be added
        name: 'Planarock',
        description: 'Planarock desc..',
        imageUrl: Planarock
    }, */
    {
        id: 15,
        fullName: 'Dämmplatte Tegarock® (L)',
        name: 'Tegarock (L)',
        descriptions: [{
            description: 'Druckbelastbare Steinwolle-Dämmplatte. In Kombination mit zusätzlicher Abdeckung aus Holzspanplatte zeitweise begehbar und mit leichten Gegenständen belastbar. Nicht für die wohnähnliche Nutzung geeignet.',
            regex: /^.*$/
        }],
        imageUrl: TegarockL,
        fullDescription:
            <ul>
                <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
                <li>nichtbrennbar</li>
                <li>Schmelzpunkt &gt; 1000°C</li>
                <li>nicht glimmend</li>
                <li>wärme- und schalldämmend</li>
                <li>diffusionsoffen</li>
                <li>recycelbar</li>
                <li>KEYMARK Güteüberwachung</li>
            </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/tegarock-l/"
    },
    /* old / removed products
    {
        id: 16,
        fullName: '', // don't forget to set if this product should be added
        name: 'Tegarock',
        description: 'Druckbelastbare Steinwolle-Dämmplatte. In Kombination mit zusätzlicher Abdeckung aus Holzspanplatte zeitweise begehbar und mit leichten Gegenständen belastbar. Nicht für die wohnähnliche Nutzung geeignet.',
        imageUrl: Tegarock
    },
    {
        id: 17,
        fullName: '', // don't forget to set if this product should be added
        name: 'Varirock',
        description: 'Varirock desc..',
        imageUrl: Varirock
    } */
    {
        id: 18,
        fullName: 'Trittschalldämmplatte Floorrock® Acoustic CP5',
        name: 'Floorrock Acoustic CP5',
        descriptions: [{
            description: 'Steinwolle-Dämmplatte für die Trittschall-, Luftschall- und Wärmedämmung von Geschossdecken und Böden unter schwimmenden Mörtel- und Fließestrichen.',
            regex: /^.*$/
        }],
        imageUrl: FloorrockAcousticCP5,
        fullDescription: <ul>
            <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
            <li>nichtbrennbar, Euroklasse A1</li>
            <li>Schmelzpunkt &gt; 1000°C</li>
            <li>nicht glimmend</li>
            <li>geeignet unter Böden mit mittleren Nutzlasten</li>
            <li>wasserabweisend</li>
            <li>diffusionsoffen</li>
            <li>schnell und einfach zu verarbeiten</li>
            <li>recycelbar</li>
            <li>KEYMARK Güteüberwachung</li>
        </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/floorrock-acoustic-cp5"
    },
    {
        id: 19,
        fullName: 'Ausgleichsplatte Floorrock® Therm',
        name: 'Floorrock Therm',
        descriptions: [{
            description: 'Druckfeste Steinwolle-Dämmplatte für die zusätzliche Wärmedämmung und den Höhenausgleich unter schwimmenden Estrichen.',
            regex: /^.*$/
        }],
        imageUrl: FloorrockTherm,
        fullDescription: <ul>
            <li>Wärmedämmstoff für Gebäude – werkmäßig hergestellte Mineralwolle (MW) gem. DIN EN 13162</li>
            <li>nichtbrennbar, Euroklasse A1</li>
            <li>Schmelzpunkt &gt; 1000°C</li>
            <li>nicht glimmend</li>
            <li>wärmedämmend</li>
            <li>wasserabweisend</li>
            <li>diffusionsoffen</li>
            <li>druckbelastbar, 70 kPa</li>
            <li>schnell und einfach zu verarbeiten</li>
            <li>recycelbar</li>
        </ul>,
        productLink: "https://www.rockwool.com/de/produkte/produktuebersicht/floorrock-therm/"
    },
]
